<template>
    <div class="a_m">
        <div class="a_m__b" :class="dragging && 'dragging'">
            <vueScroll :ops="ops">
                <draggable 
                    v-model="routersList"
                    :forceFallback="true"
                    ghost-class="ghost"
                    draggable=".a_i"
                    group="aside_menu"
                    :options="{delay: 300}"
                    @start="dragging = true"
                    @end="dragging = false">
                    <router-link 
                        v-for="item in routersList" 
                        :key="item.name" 
                        :to="{ name: item.name }"
                        class="a_m__b--i a_i">
                        <a-tooltip 
                            placement="right" 
                            arrowPointAtCenter
                            destroyTooltipOnHide>
                            <template slot="title">
                                <router-link :to="{ name: item.name }" style="color: #ffffff;">
                                    {{ item.title }}
                                </router-link>
                            </template>
                            <MenuItem :item="item" />
                        </a-tooltip>
                    </router-link>
                </draggable>
            </vueScroll>
        </div>
    </div>
</template>

<script>
import vueScroll from 'vuescroll'
import mixins from '../mixins.js'
import MenuItem from './MenuItem.vue'
export default {
    components: {
        MenuItem,
        vueScroll
    },
    mixins: [mixins]
}
</script>

<style lang="scss" scoped>
.a_m{
    background: #eff2f5;
    width: 60px;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 50;
    left: 0;
    border-right: 1px solid var(--border2);
    &__b{
        height: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
        &::-webkit-scrollbar-thumb{
            display: none;
        }
        &::v-deep{
            .__rail-is-vertical{
                background: transparent!important;
                .__bar-is-vertical{
                    border: 0px!important;
                }
            }
        }
        &.dragging{
            .a_m__b--i{
                &::v-deep{
                    .icon{
                        animation-delay: -0.65; 
                        animation-duration: .20s 
                    }
                }
                &:nth-child(2n) {
                    &::v-deep{
                        .icon{
                            animation-name: JiggleEffect;
                            animation-iteration-count: infinite;
                            transform-origin: 50% 10%;
                            -webkit-transition: all .2s ease-in-out;
                        }
                    }
                }
                &:nth-child(2n-1) {
                    &::v-deep{
                        .icon{
                            animation-name: JiggleEffect2;
                            animation-iteration-count: infinite;
                            animation-direction: alternate;
                            transform-origin: 30% 5%;
                        }
                    }
                }
            }
        }
    }
    .a_m__b--i{
        text-align: center;
        display: block;
        padding: 11px 10px;
        color: #354052;
        transition: color .3s ease;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        background: transparent;
        position: relative;
        &:first-child{
            padding-top: 14px;
        }
        &:last-child{
            padding-bottom: 15px;
        }
        &:hover{
            background: #e3e6ea;
            color: var(--blue);
        }
        &.router-link-active{
            color: var(--blue);
        }
    }
}
</style>