import { mapState } from 'vuex'
import draggable from "vuedraggable"
export default {
    components: {
        draggable
    },
    data() {
        return {
            dragging: false,
            ops: {
                scrollPanel: {
                    scrollingX: false
                },
                vuescroll: {
                    mode: 'native',
                    locking: false
                },
                bar: {
                    background: "#ccc",
                    onlyShowBarOnScroll: false
                }
            }
        }
    },
    computed: {
        ...mapState({
            routers: state => state.navigation.routerList
        }),
        routersList: {
            get() {
                return this.routers.filter(f => f.isShow)
            },
            set(val) {
                this.$store.dispatch('navigation/changeRouterList', val)
            }
        }
    },
    methods: {
        changeDraggable(e) {
            console.log(e, 'changeDraggable')
        }
    }
}